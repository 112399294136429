import styled from 'styled-components';

import { FontWeights } from '../../../styles/fonts';
import { Colors } from '../../../styles/colors';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';

export const Breadcrumbs = ({ children }: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return (
    <ErrorBoundary message="Atoms\Breadcrumbs">
      <BreadcrumbsWrapper>{children}</BreadcrumbsWrapper>
    </ErrorBoundary>
  );
};

export const Breadcrumb = styled.li<BreadcrumbProps>`
  display: inline-block;
  margin: 0 0 16px;
  font-weight: ${FontWeights.Bold};

  &:not(:last-of-type) {
    font-weight: ${FontWeights.Regular};
  }

  a {
    color: ${(props) => (props.disabled ? Colors.GreyLightest : Colors.Black)};
    transition: color ease-in-out 0.3s;
    text-decoration: none;

    &:hover,
    &:active {
      color: ${(props) => (props.disabled ? Colors.GreyLightest : Colors.Black)};
      text-decoration: none;
      cursor: ${(props) => (props.disabled ? 'text' : 'pointer')};
    }
  }

  &:not(:first-of-type) {
    &:before {
      content: '>';
      display: inline-block;
      font-weight: ${FontWeights.Bold};
      color: ${Colors.Primary};
      padding: 0 8px;
    }
  }
`;

const BreadcrumbsWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export interface BreadcrumbProps extends React.ButtonHTMLAttributes<HTMLLIElement> {
  disabled?: boolean;
}
